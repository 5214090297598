export const CART_TOKEN_COOKIE_KEY = 'cart-token'
export const CART_TOKEN_HEADER_KEY = 'X-Cart-Token'

export const CART_LINE_ITEM_TYPES = [
  'product',
  'gwp',
  'free_sample',
  'redeemable',
  'loyalty_gift',
  'gift_box',
] as const

export const CART_EXCLUSIVE_OFFERS_SHIPPING_SOURCES = 'shipping_method'
export const CART_EXCLUSIVE_OFFERS_PROGRESS_SOURCES = 'progress'

export const CART_EXCLUSIVE_OFFERS_SOURCES = [
  CART_EXCLUSIVE_OFFERS_SHIPPING_SOURCES,
  CART_EXCLUSIVE_OFFERS_PROGRESS_SOURCES,
] as const
